<template>
  <nav class="navbar navbar-expand-xl">
    <div class="container">
      <a class="navbar-brand" href="#">
        <img src="@/assets/images/logo.png" alt="logo" class="img-fluid">
      </a>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav m-auto">
          <li class="nav-item">
            <a class="nav-link" @click.prevent="scrollTo('#services')" href="#services">Services</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" @click.prevent="scrollTo('#portfolio')" href="#portfolio">Portfolio</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" @click.prevent="scrollTo('#reviews')" href="#reviews">Reviews</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" @click.prevent="scrollTo('#contacts')" href="#contacts">Contacts</a>
          </li>
        </ul>
        <div class="info">
          <div class="buttons">
            <a href="#form" @click.prevent="showApplication" class="btn btn-red">Leave request</a>
          </div>
        </div>
        <div class="lang">
          <span class="active">En</span>
          <span>/</span>
          <a href="/ua">Ua</a>
          <span>/</span>
          <a href="/ru">Ru</a>
        </div>
      </div>
      <button @click="showMenu" class="navbar-toggler" type="button">
        <span></span>
        <span></span>
        <span></span>
      </button>
    </div>
  </nav>
  <div class="menu">
    <div class="menu__overlay"></div>
    <div class="menu__container">
      <div class="lang">
          <span class="active">En</span>
          <span>/</span>
          <a href="/ua">Ua</a>
          <span>/</span>
          <a href="/ru">Ru</a>
      </div>
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" @click.prevent="showMenu('#services')" href="#services">Services</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" @click.prevent="showMenu('#portfolio')" href="#portfolio">Portfolio</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" @click.prevent="showMenu('#reviews')" href="#reviews">Reviews</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" @click.prevent="showMenu('#contacts')" href="#contacts">Contacts</a>
        </li>
      </ul>
      <div class="info">
        <div class="buttons">
          <a href="#" @click.prevent="showApplication" class="btn btn-red">Leave request</a>
        </div>
      </div>
    </div>
  </div>
  <section class="main">
    <div class="container">
      <div class="row">
        <div class="col-xxl-5 col-lg-6 py-3 order-lg-1 fromBottom">
          <img src="@/assets/images/main.png" alt="image" class="img-fluid mx-auto d-block">
        </div>
        <div class="col-xxl-5 offset-xxl-1 col-lg-6 py-3 fromBottom">
          <h1>Your skilled IT team</h1>
          <p class="subtitle">The right experts in solving business tasks of any complexity.</p>
          <p>For over 9 years we develop web applications, large accounting and business automation systems. We work
            with all modern technologies, including blockchain with further maintenance and management of your
            project.</p>
          <div class="buttons">
            <a href="#contacts" @click.prevent="scrollTo('#contacts')" class="btn">Contact us</a>
            <a href="#services" @click.prevent="scrollTo('#services')" class="btn btn-red">Learn more</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="advantage">
    <div class="container">
      <div class="row align-items-stretch">
        <div class="col-md-4 advantage-item fromBottom">
          <div class="card">
            <div class="card-body">
              <div class="card-title">9</div>
              <div class="card-text">
                For over 9 years as a team on the market
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 advantage-item fromBottom">
          <div class="card">
            <div class="card-body">
              <div class="card-title">12</div>
              <div class="card-text">
                12 years is the minimum experts experience
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 advantage-item fromBottom">
          <div class="card">
            <div class="card-body">
              <div class="card-title">60+</div>
              <div class="card-text">
                Successful projects that we are proud of
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="offer" id="services">
    <div class="container">
      <div class="row">
        <div class="col-xxl-11 offset-xxl-1 fromBottom">
          <h2>What do we offer?</h2>
        </div>
        <div class="col-xxl-5 offset-xxl-1 col-lg-6 py-3 fromBottom">
          <div class="image__static">
            <!--            <div class="image__rotate">-->
            <!--              <img src="@/assets/images/logo/logo1.svg" class="logo logo-1" alt="logo">-->
            <!--              <img src="@/assets/images/logo/logo2.svg" class="logo logo-2" alt="logo">-->
            <!--              <img src="@/assets/images/logo/logo3.svg" class="logo logo-3" alt="logo">-->
            <!--              <img src="@/assets/images/logo/logo4.svg" class="logo logo-4" alt="logo">-->
            <!--              <img src="@/assets/images/logo/logo5.svg" class="logo logo-5" alt="logo">-->
            <!--            </div>-->
            <img src="@/assets/images/offer.png" alt="image" class="img-fluid  mx-auto d-block">
          </div>
        </div>
        <div class="col-xxl-5 col-lg-6 py-3">
          <div class="card fromBottom">
            <div class="card-body">
              <div class="card-title">
                <img src="@/assets/images/icon/integration.svg" alt="icon" class="img-fluid">
                <span>Blockchain integration</span>
              </div>
              <div class="card-text">We implement various projects using Blockchain technology (EOS, NEM, TRON,
                Ethereum).
              </div>
            </div>
          </div>
          <div class="card fromBottom">
            <div class="card-body">
              <div class="card-title">
                <img src="@/assets/images/icon/connection.svg" alt="icon" class="img-fluid">
                <span>Web solutions for business</span></div>
              <div class="card-text">We will develop a web platform of any complexity for your business with the
                integration of various systems. We will finalize the existing system also.
              </div>
            </div>
          </div>
          <div class="card fromBottom">
            <div class="card-body">
              <div class="card-title">
                <img src="@/assets/images/icon/website.svg" alt="icon" class="img-fluid">
                <span>Websites and online stores</span>
              </div>
              <div class="card-text">We will develop a corporate website or online store and perform all the necessary
                integrations.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="better">
    <div class="container">
      <div class="row">
        <div class="col-xxl-11 offset-xxl-1 fromBottom">
          <h2>Why are we better?</h2>
        </div>
        <div class="col-xxl-5 col-lg-6 py-3 order-lg-1 fromBottom">
          <img src="@/assets/images/better.png" alt="image" class="img-fluid mx-auto d-block">
        </div>
        <div class="col-xxl-5 offset-xxl-1 col-lg-6 py-3 fromBottom">
          <p>Advanced technologies and a close-knit team.</p>
          <p>Vast experience in process automation in large and small companies.</p>
          <p>Sufficient expertise to solve problems of varying complexity on a turnkey basis.</p>
          <p>We will help you describe the requirements and fully complete the task: develop, implement, train staff and
            provide support.</p>
          <p class="fw-bold">LEAVE A REQUEST and get a FREE qualified consultation of our experts.</p>
          <div class="buttons">
            <a href="#" @click.prevent="showConsultation" class="btn btn-red">Request the consultation</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="how-work">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xxl-11 offset-xxl-1 fromBottom">
          <h2>How do we work?</h2>
        </div>
        <div class="col-xxl-5 offset-xxl-1 col-lg-6 py-3 fromBottom">
          <img src="@/assets/images/how-work.png" alt="image" class="img-fluid mx-auto d-block">
        </div>
        <div class="col-xxl-5 col-lg-6 py-3">
          <div class="how-work__item fromBottom">
            <h3>
              <img src="@/assets/images/icon/mind.svg" alt="icon">
              <span>Expertise</span>
            </h3>
            <p>We will help you to plan your project correctly for maximum efficiency and budgeting. You will feel
              confident and comfortable as the project progresses.</p>
          </div>
          <div class="how-work__item fromBottom">
            <h3>
              <img src="@/assets/images/icon/rocket.svg" alt="icon">
              <span>Speed</span>
            </h3>
            <p>Following the concept of CI/CD allows us to show visible results at all stages of the project. You will
              be able to start using the product in no time.</p>
          </div>
          <div class="how-work__item fromBottom">
            <h3>
              <img src="@/assets/images/icon/thumb-up.svg" alt="icon">
              <span>Quality</span>
            </h3>
            <p>Thorough testing at all stages of development, as well as constant product performance monitoring,
              minimizes failures risks and increases troubleshooting speed.</p>
          </div>
          <div class="how-work__item fromBottom">
            <h3>
              <img src="@/assets/images/icon/technologies.svg" alt="icon">
              <span>Manufacturability</span>
            </h3>
            <p>We constantly monitor rapidly developing technologies and use them in our work.</p>
          </div>
          <div class="how-work__item fromBottom">
            <h3>
              <img src="@/assets/images/icon/shield.svg" alt="icon">
              <span>Security</span>
            </h3>
            <p>Using a wide range of data protection tools, as well as constant security threats monitoring will give
              you confidence in the safety of your data.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="what-do" id="portfolio">
    <div class="container">
      <h2 class="fromBottom">What have we done?</h2>
      <!-- Slider main container -->
      <div class="swiper-container fromBottom">
        <!-- If we need navigation buttons -->
        <div class="swiper-button">
          <div class="swiper-button-line"></div>
          <div class="swiper-button-prevc">
            <img src="@/assets/images/arrowLeft.svg" alt="icon">
          </div>
          <div class="swiper-button-nextc">
            <img src="@/assets/images/arrowRight.svg" alt="icon">
          </div>
        </div>

        <!-- Additional required wrapper -->
        <div class="swiper-wrapper">
          <!-- Slides -->
          <div class="swiper-slide">
            <div class="card mb-3">
              <div class="card-body">
                <div class="row align-items-center my-3">
                  <div class="col-lg-5 border-end">
                    <div class="info mx-3">
                      <div class="info__title">FreeTeam</div>
                      <div class="info__description">FreeTeam - online poker school, backing fund system</div>
                      <div class="info__task">
                        <div class="info__task-title">
                          <img src="@/assets/images/icon/portfolio-task.svg" alt="icon"><span>Project task</span>
                        </div>
                        <div class="info__task-description">
                          Design and implementation of an online platform for a full
                          training cycle, from student's submitted questionnaire automatic analysis, to a success record
                          of tournaments series played during and after training.
                        </div>
                      </div>
                      <div class="info__what">
                        <div class="info__what-title">
                          <img src="@/assets/images/icon/portfolio-what.svg" alt="icon"><span>What is done?</span>
                        </div>
                        <div class="info__what-description">
                          Online training platform was launched. Modular implementation. The main modules are:
                          applications receiving and processing, online training system, Funds accounting system (full
                          process automation of mutual settlements between players and backing funds), forum, online
                          store, news, gamification elements with internal currency, blockchain integration is possible.
                        </div>
                      </div>
                      <div class="info__link">
                        <img src="@/assets/images/icon/portfolio-link.svg" alt="icon">
                        <a href="https://freeteamclub.com/" target="_blank">freeteamclub.com</a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-7 image">
                    <img src="@/assets/images/portfolio-freeteam.png" alt="image">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="card mb-3">
              <div class="card-body">
                <div class="row align-items-center my-3">
                  <div class="col-lg-5 border-end">
                    <div class="info mx-3">
                      <div class="info__title">EURO2012</div>
                      <div class="info__description">European Football Championship 2012</div>
                      <div class="info__task">
                        <div class="info__task-title">
                          <img src="@/assets/images/icon/portfolio-task.svg" alt="icon"><span>Project task</span>
                        </div>
                        <div class="info__task-description">
                          Design and development of an online platform for candidates selection and testing for stewards
                          position for further training, stadiums maintenance and security.
                        </div>
                      </div>
                      <div class="info__what">
                        <div class="info__what-title">
                          <img src="@/assets/images/icon/portfolio-what.svg" alt="icon"><span>What is done?</span>
                        </div>
                        <div class="info__what-description">
                          Developed and deployed a platform for registering, requirements review and potential
                          candidates testing. A unique set of tests and reports were developed involving a professional
                          psychologist to select the most suitable candidates for the steward role.
                        </div>
                      </div>
                      <div class="info__link">
                        <img src="@/assets/images/icon/portfolio-link.svg" alt="icon">
                        <a href="https://en.wikipedia.org/wiki/UEFA_Euro_2012" target="_blank">UEFA EURO 2012</a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-7 image">
                    <img src="@/assets/images/porfolio-euro.png" alt="image">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="card mb-3">
              <div class="card-body">
                <div class="row align-items-center my-3">
                  <div class="col-lg-5 border-end">
                    <div class="info mx-3">
                      <div class="info__title">ART VIVACE Jewelry</div>
                      <div class="info__description">Art Vivace jewelry house</div>
                      <div class="info__task">
                        <div class="info__task-title">
                          <img src="@/assets/images/icon/portfolio-task.svg" alt="icon"><span>Project task</span>
                        </div>
                        <div class="info__task-description">
                          Company corporate website development for own production
                          jewelry presentation, promotion and sale in the international market.
                        </div>
                      </div>
                      <div class="info__what">
                        <div class="info__what-title">
                          <img src="@/assets/images/icon/portfolio-what.svg" alt="icon"><span>What is done?</span>
                        </div>
                        <div class="info__what-description">
                          Company corporate style design was created from scratch to the last detail. Site and product
                          catalog structure has been worked out. A full cycle from searching and ordering to selling of
                          own production jewelry on the company's website has been implemented
                        </div>
                      </div>
                      <div class="info__link">
                        <img src="@/assets/images/icon/portfolio-link.svg" alt="icon">
                        <a href="https://artvivace.com/" target="_blank">ART VIVACE Jewelry</a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-7 image">
                    <img src="@/assets/images/portfolio-art.png" alt="image">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="card mb-3">
              <div class="card-body">
                <div class="row align-items-center my-3">
                  <div class="col-lg-5 border-end">
                    <div class="info mx-3">
                      <div class="info__title">Shpilkina. Shoe trend</div>
                      <div class="info__description">Dropshipping operator Shpilkina</div>
                      <div class="info__task">
                        <div class="info__task-title">
                          <img src="@/assets/images/icon/portfolio-task.svg" alt="icon"><span>Project task</span>
                        </div>
                        <div class="info__task-description">
                          Footwear orders and booking management system development to ensure company partners remote work.
                        </div>
                      </div>
                      <div class="info__what">
                        <div class="info__what-title">
                          <img src="@/assets/images/icon/portfolio-what.svg" alt="icon"><span>What is done?</span>
                        </div>
                        <div class="info__what-description">
                          A non-public order management system based on the principles of e-commerce has been
                          designed and implemented. A complex of integrations with the internal 1C system has been
                          completed. In-system events notifications system based on Telegram integration
                          was launched.
                        </div>
                      </div>
                      <div class="info__link d-none">
                        <img src="@/assets/images/icon/portfolio-link.svg" alt="icon">
                        <a href="#" target="_blank">Shpilkina</a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-7 image">
                    <img src="@/assets/images/portfolio-shpilkina.png" alt="image">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="card mb-3">
              <div class="card-body">
                <div class="row align-items-center my-3">
                  <div class="col-lg-5 border-end">
                    <div class="info mx-3">
                      <div class="info__title">NaRazbor</div>
                      <div class="info__description">NaRazbor marketplace</div>
                      <div class="info__task">
                        <div class="info__task-title">
                          <img src="@/assets/images/icon/portfolio-task.svg" alt="icon"><span>Project task</span>
                        </div>
                        <div class="info__task-description">
                          Multi-vendor marketplace operating in the niche of selling used spare parts development.
                        </div>
                      </div>
                      <div class="info__what">
                        <div class="info__what-title">
                          <img src="@/assets/images/icon/portfolio-what.svg" alt="icon"><span>What is done?</span>
                        </div>
                        <div class="info__what-description">
                          A marketplace has been designed, developed and launched, enabling everyone to publish and
                          sell used parts. A specialized product management system has been developed. System
                          grows, develops and increases functionality.
                        </div>
                      </div>
                      <div class="info__link">
                        <img src="@/assets/images/icon/portfolio-link.svg" alt="icon">
                        <a href="https://narazbor.com/" target="_blank">NaRazbor.com</a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-7 image">
                    <img src="@/assets/images/portfolio-narazbor.png" alt="image">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- If we need pagination -->
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </section>
  <section class="more-work">
    <div class="container">
      <div class="row">
        <div class="col-xxl-5 offset-xxl-1 col-lg-6 py-3 fromBottom">
          <img src="@/assets/images/more-work.png" alt="image" class="img-fluid mx-auto d-block">
        </div>
        <div class="col-xxl-5 col-lg-6 py-3 fromBottom">
          <h2>Want to see more works?</h2>
          <p>Leave a request and we will be happy to show and tell you how we have implemented more than 60 of our
            successful projects</p>
          <div class="buttons">
            <a href="#" @click.prevent="showApplication" class="btn btn-red">Leave request</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="recommend" id="reviews">
    <div class="container">
      <div class="row">
        <div class="col-xxl-11 offset-xxl-1 fromBottom">
          <h2>We are recommended</h2>
        </div>
        <div class="col-lg-4 fromBottom">
          <div class="card">
            <div class="card-body">
              We have been cooperating for over ten years. Such cooperation had turned into a strong partnership long
              ago. During this time, we were able to automate most of our processes and significantly scale our
              businesses. All work was done on a turnkey basis, from idea formalization to solution implementation and
              further support. We worked together on all our projects. I recommend!
            </div>
          </div>
          <div class="info">
            <div class="info__image">
              <img src="@/assets/images/reviews-freeteam.jpg" alt="photo">
            </div>
            <div>
              <div class="info__name">Kovalenko Pavel</div>
              <div class="info__position">CEO FreeTeam, AnimaUnity, Skillonomy</div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 fromBottom">
          <div class="card">
            <div class="card-body">
              We needed to quickly select and train stewards to maintain and secure Ukrainian stadiums for Euro2012. We
              have developed an online system for selecting candidates using a set of tests specially selected for this
              purpose by a professional psychologist. This system allowed us not only to receive high-quality personnel
              on time, but even to create a personnel reserve.
            </div>
          </div>
          <div class="info">
            <div class="info__image">
              <img src="@/assets/images/reviews-euro.jpg" alt="photo">
            </div>
            <div>
              <div class="info__name">Dmitry Savitsky</div>
              <div class="info__position">Steward Training Course Head</div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 fromBottom">
          <div class="card">
            <div class="card-body">
              We racked our brains on how it should look and work. After all, it's important not only to post some
              products photoes on the Internet, but to convey their beauty, sophistication, style and exclusivity. It's
              also important to understand customers and always be in touch with them. All these questions were
              carefully worked out and made it possible to create a worthy resource that represents us in Ukraine and
              abroad.
            </div>
          </div>
          <div class="info">
            <div class="info__image">
              <img src="@/assets/images/reviews-art.jpg" alt="photo">
            </div>
            <div>
              <div class="info__name">Peter Gray</div>
              <div class="info__position">ART VIVACE JEWELRY Owner</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="how-work2">
    <div class="container">
      <div class="row">
        <div class="col-xxl-11 offset-xxl-1 fromBottom">
          <h2>How do we work?</h2>
        </div>
        <div class="col-lg-4 fromBottom mb-4">
          <div class="card">
            <div class="card-body">
              <span>01</span>
              <img src="@/assets/images/icon/phone-call.svg" alt="icon" class="img-fluid d-block">
              <div>
                <div class="card-title">Request</div>
                <div class="card-text">Just leave a request on the site or contact us by phone.</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 fromBottom mb-4">
          <div class="card">
            <div class="card-body">
              <span>02</span>
              <img src="@/assets/images/icon/web-browser.svg" alt="icon" class="img-fluid">
              <div>
                <div class="card-title">Work</div>
                <div class="card-text">From this moment we will already start working with your project and bring it to
                  the end.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 fromBottom mb-4">
          <div class="card">
            <div class="card-body">
              <span>03</span>
              <img src="@/assets/images/icon/checklist.svg" alt="icon" class="img-fluid">
              <div>
                <div class="card-title">Result</div>
                <div class="card-text">At every stage, you will have clear and tangible results.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="discuss" id="contacts">
    <div class="container">
      <div class="row">
        <div class="col-xxl-5 offset-xxl-1 col-lg-6 fromBottom">
          <h2>Let's discuss your project</h2>
          <div class="discuss__item py-3">
            <div>
              <div class="discuss__item-name">Sergii</div>
              <div class="discuss__item-soc">
                <a href="viber://chat?number=380952355556">
                  <img src="@/assets/images/icon/viber.svg" alt="icon" class="img-fluid">
                </a>
                <a href="https://t.me/SergiiMogylko">
                  <img src="@/assets/images/icon/telegram.svg" alt="icon" class="img-fluid">
                </a>
              </div>
            </div>
          </div>
          <div class="discuss__item">
            <div>
              <div class="discuss__item-name">Max</div>
              <div class="discuss__item-soc">
                <a href="https://t.me/MaxBasmanov">
                  <img src="@/assets/images/icon/telegram.svg" alt="icon" class="img-fluid">
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl-5 col-lg-6 fromBottom">
          <form
            @submit.prevent="submitForm('Write to us')"
            class="card"
          >
            <div class="card-body">
              <div class="card-title">Contact us</div>
              <div class="form-floating mb-3">
                <input type="text" v-model="form.name" class="form-control" id="inputName" placeholder="Your name">
                <label for="inputName">Your name</label>
              </div>
              <div class="form-floating mb-3">
                <input type="text" v-model="form.phone" class="form-control" id="inputPhone" placeholder="Phone">
                <label for="inputPhone">Phone</label>
              </div>
              <div class="form-floating mb-3">
                <input type="text" v-model="form.theme" class="form-control" id="inputTheme" placeholder="Subject">
                <label for="inputTheme">Subject</label>
              </div>
              <div class="form-floating mb-4">
                <textarea v-model="form.message" class="form-control" id="inputMessage" rows="4" placeholder="Message">
                </textarea>
                <label for="inputMessage">Message</label>
              </div>
              <div class="text-center">
                <button type="submit" class="btn btn-red">Send</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-auto footer-item">Bucharest. Romania</div>
        <div class="col-auto footer-item">MAXCODING.eu &copy; 2025</div>
      </div>
    </div>
  </footer>
  <div class="form-application">
    <div class="form-application__overlay"></div>
    <div class="form-application__container">
      <form
        @submit.prevent="submitForm('Leave request')"
        class="card"
      >
        <div class="card-body">
          <button @click="showApplication" type="button" class="card-close">
            <span></span>
            <span></span>
          </button>
          <div class="card-title">Leave request</div>
          <div class="form-floating mb-3">
            <input type="text" v-model="form.name" class="form-control" id="inputName1" placeholder="Your name">
            <label for="inputName1">Your name</label>
          </div>
          <div class="form-floating mb-3">
            <input type="text" v-model="form.phone" class="form-control" id="inputPhone1" placeholder="Phone">
            <label for="inputPhone1">Phone</label>
          </div>
          <div class="form-floating mb-4">
            <textarea v-model="form.message" class="form-control" id="inputMessage1" rows="4" placeholder="Message">
            </textarea>
            <label for="inputMessage1">Message</label>
          </div>
          <div class="text-center">
            <button type="submit" class="btn btn-red">Send</button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="form-consultation">
    <div class="form-consultation__overlay"></div>
    <div class="form-consultation__container">
      <form
        @submit.prevent="submitForm('Request the consultation')"
        class="card"
      >
        <div class="card-body">
          <button @click="showConsultation" type="button" class="card-close">
            <span></span>
            <span></span>
          </button>
          <div class="card-title">Get a consultation</div>
          <div class="form-floating mb-3">
            <input type="text" v-model="form.name" class="form-control" id="inputName2" placeholder="Your name">
            <label for="inputName2">Your name</label>
          </div>
          <div class="form-floating mb-3">
            <input type="text" v-model="form.phone" class="form-control" id="inputPhone2" placeholder="Phone">
            <label for="inputPhone2">Phone</label>
          </div>
          <div class="form-floating mb-4">
            <textarea v-model="form.message" class="form-control" id="inputMessage2" rows="4" placeholder="Message">
            </textarea>
            <label for="inputMessage2">Message</label>
          </div>
          <div class="text-center">
            <button type="submit" class="btn btn-red">Send</button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div v-if="this.success.show" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
    {{ success.message }}
    <button @click="this.success.show = false" type="button" class="btn-close" data-bs-dismiss="alert"
            aria-label="Close"></button>
  </div>
  <div v-if="this.error.show" class="alert alert-danger alert-dismissible fade show mt-5" role="alert">
    {{ error.message }}
    <button @click="this.error.show = false" type="button" class="btn-close" data-bs-dismiss="alert"
            aria-label="Close"></button>
  </div>
</template>

<script>

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { Swiper, Autoplay, Navigation, Pagination } from 'swiper'

Swiper.use([Autoplay, Navigation, Pagination])

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

export default {
  name: 'App',
  data () {
    return {
      menu: false,
      application: false,
      consultation: false,
      slider: null,
      form: {
        name: '',
        theme: '',
        phone: '',
        message: ''
      },
      success: {
        message: 'Your message was sent!',
        show: false
      },
      error: {
        message: 'Please input data!',
        show: false
      }
    }
  },
  mounted () {
    this.slider = new Swiper('.swiper-container', {
      // Optional parameters
      direction: 'horizontal',
      loop: true,
      slidesPerView: 1,
      centeredSlides: true,
      speed: 500,
      autoplay: {
        delay: 4000
      },

      // If we need pagination
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },

      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-nextc',
        prevEl: '.swiper-button-prevc'
      }
    })
    gsap.utils.toArray('.fromBottom').forEach(function (elem) {
      gsap.fromTo(elem,
        {
          y: '75px',
          alpha: 0
        },
        {
          duration: 1,
          y: 0,
          alpha: 1,
          scrollTrigger: {
            trigger: elem,
            start: 'top bottom'
          }
        })
    })
    this.tlMenu = gsap.timeline({ paused: true })
    this.tlMenu
      .to('.menu', { duration: 0, display: 'flex' })
      .fromTo('.menu .lang, .menu .nav-item, .menu .info > div', 0.3,
        {
          alpha: 0,
          x: gsap.utils.wrap(['-50%', '50%'])
        }, {
          alpha: 1,
          x: 0,
          stagger: 0.1
        })
      .to('.navbar-toggler span:nth-child(1)', {
        duration: 0.3,
        rotateZ: 43,
        translateY: '-3px',
        transformOrigin: 'top left'
      }, 0)
      .to('.navbar-toggler span:nth-child(2)', { duration: 0.3, alpha: 0 }, 0)
      .to('.navbar-toggler span:nth-child(3)', {
        duration: 0.3,
        rotateZ: -43,
        translateY: '3px',
        transformOrigin: 'top left'
      }, 0)

    this.tlApplication = gsap.timeline({ paused: true })
    this.tlApplication
      .to('.form-application', { duration: 0, display: 'flex' })
      .fromTo('.form-application .card', 0.3,
        {
          y: '-100%',
          alpha: 0
        }, {
          alpha: 1,
          y: 0
        })

    this.tlConsultation = gsap.timeline({ paused: true })
    this.tlConsultation
      .to('.form-consultation', { duration: 0, display: 'flex' })
      .fromTo('.form-consultation .card', 0.3,
        {
          y: '-100%',
          alpha: 0
        }, {
          alpha: 1,
          y: 0
        })

    // gsap.to('.image__rotate', { rotate: 360, duration: 60, repeat: -1, ease: 'none' })
    // gsap.to('.image__rotate .logo', { rotate: -360, duration: 60, repeat: -1, ease: 'none' })
  },
  methods: {
    scrollTo: function (target) {
      gsap.to(window, { scrollTo: target })
    },
    showMenu: function (target) {
      if (!this.menu) {
        document.querySelector('body').style.overflowY = 'hidden'
        this.tlMenu.play()
        this.menu = true
      } else {
        document.querySelector('body').style.overflowY = 'initial'
        this.tlMenu.reverse()
        gsap.to(window, { duration: 0.2, scrollTo: target, ease: 'power1.in' })
        this.menu = false
      }
    },
    showApplication: function (target) {
      if (!this.application) {
        document.querySelector('body').style.overflowY = 'hidden'
        this.tlApplication.play()
        this.application = true
      } else {
        document.querySelector('body').style.overflowY = 'initial'
        this.tlApplication.reverse()
        this.application = false
      }
    },
    showConsultation: function (target) {
      if (!this.consultation) {
        document.querySelector('body').style.overflowY = 'hidden'
        this.tlConsultation.play()
        this.consultation = true
      } else {
        document.querySelector('body').style.overflowY = 'initial'
        this.tlConsultation.reverse()
        this.consultation = false
      }
    },
    submitForm: function (form) {
      this.success.show = false
      this.error.show = false
      const formData = new FormData()
      formData.append('subject', 'Request from site')
      formData.append('form', form)
      formData.append('name', this.form.name)
      formData.append('phone', this.form.phone)
      formData.append('theme', this.form.theme)
      formData.append('message', this.form.message)

      if (this.form.name && this.form.phone && form && this.form.message) {
        fetch('/api/mail.php', {
          method: 'POST',
          body: formData
        })
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            if (data.send) {
              this.success.show = true
              this.form.name = ''
              this.form.phone = ''
              this.form.theme = ''
              this.form.message = ''
            }
          })
      } else {
        this.error.show = true
      }
    }
  }
}
</script>

<style lang="scss" src="@/assets/scss/app.scss"></style>
